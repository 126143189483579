/* tslint:disable */
/* eslint-disable */
/**
 * Bridge API
 * Bridge API 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AssetRef
 */
export interface AssetRef {
    /**
     * ID of the asset reference
     * @type {string}
     * @memberof AssetRef
     */
    assetRefId?: string;
    /**
     * Amount of tokens the asset references
     * @type {number}
     * @memberof AssetRef
     */
    refwNOKAmount?: number;
    /**
     * Type of the token
     * @type {string}
     * @memberof AssetRef
     */
    tokenType?: string;
    /**
     * Owner of the asset / escrowed tokens
     * @type {string}
     * @memberof AssetRef
     */
    owner?: string;
    /**
     * Identifier of the remote network to which to bridge out tokens
     * @type {string}
     * @memberof AssetRef
     */
    remoteNetwork?: string;
    /**
     * Remote recipient of the asset
     * @type {string}
     * @memberof AssetRef
     */
    remoteRecipient?: string;
}
/**
 * 
 * @export
 * @interface Balance
 */
export interface Balance {
    /**
     * Account number
     * @type {string}
     * @memberof Balance
     */
    accountNo?: string;
    /**
     * Balance amount
     * @type {number}
     * @memberof Balance
     */
    amount?: number;
    /**
     * Timestamp of the balance
     * @type {string}
     * @memberof Balance
     */
    timestamp?: string;
}
/**
 * 
 * @export
 * @interface BalanceList
 */
export interface BalanceList {
    /**
     * Collection of account balances
     * @type {Array<Balance>}
     * @memberof BalanceList
     */
    list?: Array<Balance>;
}
/**
 * 
 * @export
 * @interface BridgeOutRequest
 */
export interface BridgeOutRequest {
    /**
     * Reference ID of the asset to be bridged out
     * @type {string}
     * @memberof BridgeOutRequest
     */
    assetRefId: string;
    /**
     * Amount to bridge out
     * @type {number}
     * @memberof BridgeOutRequest
     */
    amount: number;
    /**
     * Owner of the asset
     * @type {string}
     * @memberof BridgeOutRequest
     */
    owner: string;
    /**
     * Identifier of the remote network to which to bridge out tokens
     * @type {string}
     * @memberof BridgeOutRequest
     */
    remoteNetwork: string;
    /**
     * Identifier of the recipient in the remote network
     * @type {string}
     * @memberof BridgeOutRequest
     */
    remoteRecipient: string;
}
/**
 * 
 * @export
 * @interface Contract
 */
export interface Contract {
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Contract
     */
    address: string;
}
/**
 * 
 * @export
 * @interface Contracts
 */
export interface Contracts {
    /**
     * 
     * @type {string}
     * @memberof Contracts
     */
    networkId?: string;
    /**
     * 
     * @type {ContractsCbdc}
     * @memberof Contracts
     */
    cbdc: ContractsCbdc;
    /**
     * 
     * @type {ContractsCbdc}
     * @memberof Contracts
     */
    assetReference: ContractsCbdc;
}
/**
 * 
 * @export
 * @interface ContractsCbdc
 */
export interface ContractsCbdc {
    /**
     * 
     * @type {string}
     * @memberof ContractsCbdc
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ContractsCbdc
     */
    address: string;
}
/**
 * 
 * @export
 * @interface DateInterval
 */
export interface DateInterval {
    /**
     * Start date of the transaction history interval in ISO 8601 format
     * @type {string}
     * @memberof DateInterval
     */
    dateIntervalFrom: string;
    /**
     * End date of the transaction history interval in ISO 8601 format
     * @type {string}
     * @memberof DateInterval
     */
    dateIntervalTo: string;
}
/**
 * 
 * @export
 * @interface EntityNameMapping
 */
export interface EntityNameMapping {
    /**
     * 
     * @type {string}
     * @memberof EntityNameMapping
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof EntityNameMapping
     */
    fabricName: string;
    /**
     * 
     * @type {string}
     * @memberof EntityNameMapping
     */
    utxoName: string;
    /**
     * 
     * @type {string}
     * @memberof EntityNameMapping
     */
    besuAddress: string;
    /**
     * 
     * @type {string}
     * @memberof EntityNameMapping
     */
    entityType: string;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * The account address to retrieve the balance for
     * @type {string}
     * @memberof InlineObject
     */
    account: string;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * The date to retrieve the balance for in ISO 8601 format
     * @type {string}
     * @memberof InlineObject1
     */
    date: string;
}
/**
 * 
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
    /**
     * The date to retrieve the balance for in ISO 8601 format
     * @type {string}
     * @memberof InlineObject2
     */
    date: string;
}
/**
 * 
 * @export
 * @interface IssueAssetRefRequest
 */
export interface IssueAssetRefRequest {
    /**
     * The amount of tokens to be transferred.
     * @type {number}
     * @memberof IssueAssetRefRequest
     */
    amount: number;
    /**
     * The owner identifier.
     * @type {string}
     * @memberof IssueAssetRefRequest
     */
    owner: string;
    /**
     * The private key of the owner (TODO remove this -- it is a temporary fix for the besu network).
     * @type {string}
     * @memberof IssueAssetRefRequest
     */
    ownerPrivateKey?: string;
    /**
     * The network to bridge the tokens to.
     * @type {string}
     * @memberof IssueAssetRefRequest
     */
    remoteNetwork: string;
    /**
     * The identifier of the recipient on a remote system.
     * @type {string}
     * @memberof IssueAssetRefRequest
     */
    remoteRecipient: string;
}
/**
 * 
 * @export
 * @interface IssueAssetRefResponse
 */
export interface IssueAssetRefResponse {
    /**
     * Transaction ID
     * @type {string}
     * @memberof IssueAssetRefResponse
     */
    txId?: string;
    /**
     * Asset reference ID
     * @type {string}
     * @memberof IssueAssetRefResponse
     */
    assetRefId?: string;
}
/**
 * 
 * @export
 * @interface LegalEntity
 */
export interface LegalEntity {
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    legalName: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    accountNo: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    contactPerson: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    eMail: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    LEI: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    entityType: string;
}
/**
 * 
 * @export
 * @interface RollbackEscrowRequest
 */
export interface RollbackEscrowRequest {
    /**
     * Reference ID of the assetRef for the escrow operation to be rolled back
     * @type {string}
     * @memberof RollbackEscrowRequest
     */
    assetRefId: string;
}
/**
 * 
 * @export
 * @interface RollbackEscrowResponse
 */
export interface RollbackEscrowResponse {
    /**
     * Transaction ID
     * @type {string}
     * @memberof RollbackEscrowResponse
     */
    txId?: string;
}
/**
 * 
 * @export
 * @interface ServiceStatus
 */
export interface ServiceStatus {
    /**
     * Indicates if the service is alive
     * @type {boolean}
     * @memberof ServiceStatus
     */
    isAlive?: boolean;
}
/**
 * 
 * @export
 * @interface TotalSupplyHistory
 */
export interface TotalSupplyHistory {
    /**
     * Collection of total supply history records
     * @type {Array<TotalSupplyHistoryRecord>}
     * @memberof TotalSupplyHistory
     */
    list?: Array<TotalSupplyHistoryRecord>;
}
/**
 * 
 * @export
 * @interface TotalSupplyHistoryRecord
 */
export interface TotalSupplyHistoryRecord {
    /**
     * Total supply amount
     * @type {number}
     * @memberof TotalSupplyHistoryRecord
     */
    amount?: number;
    /**
     * Timestamp of the total supply
     * @type {string}
     * @memberof TotalSupplyHistoryRecord
     */
    timestamp?: string;
}
/**
 * 
 * @export
 * @interface TransactionHistory
 */
export interface TransactionHistory {
    /**
     * 
     * @type {Array<TransactionHistoryTransactions>}
     * @memberof TransactionHistory
     */
    transactions?: Array<TransactionHistoryTransactions>;
}
/**
 * 
 * @export
 * @interface TransactionHistoryTransactions
 */
export interface TransactionHistoryTransactions {
    /**
     * Type of the transaction
     * @type {string}
     * @memberof TransactionHistoryTransactions
     */
    operation?: TransactionHistoryTransactionsOperationEnum;
    /**
     * Account number from which the transaction originated
     * @type {string}
     * @memberof TransactionHistoryTransactions
     */
    accountNoFrom?: string;
    /**
     * Account number to which the transaction was sent
     * @type {string}
     * @memberof TransactionHistoryTransactions
     */
    accountNoTo?: string;
    /**
     * Amount involved in the transaction
     * @type {number}
     * @memberof TransactionHistoryTransactions
     */
    amount?: number;
    /**
     * Transaction ID
     * @type {string}
     * @memberof TransactionHistoryTransactions
     */
    txId?: string;
    /**
     * Timestamp of the transaction
     * @type {string}
     * @memberof TransactionHistoryTransactions
     */
    timestamp?: string;
    /**
     * Own account number
     * @type {string}
     * @memberof TransactionHistoryTransactions
     */
    ownAccountNo?: string;
    /**
     * Own balance after the transaction
     * @type {number}
     * @memberof TransactionHistoryTransactions
     */
    ownBalance?: number;
    /**
     * Transaction Status
     * @type {string}
     * @memberof TransactionHistoryTransactions
     */
    status?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum TransactionHistoryTransactionsOperationEnum {
    CreateAccount = 'CreateAccount',
    Mint = 'Mint',
    Burn = 'Burn',
    TransferwNOK = 'TransferwNOK',
    Escrow = 'Escrow',
    ReleaseEscrow = 'ReleaseEscrow'
}

/**
 * 
 * @export
 * @interface VoidResponse
 */
export interface VoidResponse {
    /**
     * Indicates if the operation was successful
     * @type {boolean}
     * @memberof VoidResponse
     */
    success?: boolean;
}

/**
 * AccountMonitoringApi - axios parameter creator
 * @export
 */
export const AccountMonitoringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the balance of a specific address
         * @summary Get account balance for a specific address
         * @param {string} networkId 
         * @param {string} account The account address to retrieve the balance for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountBalance: async (networkId: string, account: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getAccountBalance.');
            }
            // verify required parameter 'account' is not null or undefined
            if (account === null || account === undefined) {
                throw new RequiredError('account','Required parameter account was null or undefined when calling getAccountBalance.');
            }
            const localVarPath = `/api/account-monitoring/{networkId}/getAccountBalance`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (account !== undefined) { 
                localVarFormParams.set('account', account as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the asset references associated with the bridge account
         * @summary Get asset references owned by the bridge
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBridgeAssetRefs: async (networkId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getBridgeAssetRefs.');
            }
            const localVarPath = `/api/account-monitoring/{networkId}/getBridgeAssetRefs`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the current balance of the account
         * @summary Get the bridge account\'s balance
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountBalance: async (networkId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getEscrowAccountBalance.');
            }
            const localVarPath = `/api/account-monitoring/{networkId}/getEscrowAccountBalance`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the balance of the account at a specific date
         * @summary Get escrow account balance at a specific date
         * @param {string} networkId 
         * @param {string} date The date to retrieve the balance for in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountBalanceAtDate: async (networkId: string, date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getEscrowAccountBalanceAtDate.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getEscrowAccountBalanceAtDate.');
            }
            const localVarPath = `/api/account-monitoring/{networkId}/getEscrowAccountBalanceAtDate`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (date !== undefined) { 
                localVarFormParams.set('date', date as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the balance history for the specified date interval
         * @summary Get escrow account balance history
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountBalanceHistory: async (networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getEscrowAccountBalanceHistory.');
            }
            // verify required parameter 'dateIntervalFrom' is not null or undefined
            if (dateIntervalFrom === null || dateIntervalFrom === undefined) {
                throw new RequiredError('dateIntervalFrom','Required parameter dateIntervalFrom was null or undefined when calling getEscrowAccountBalanceHistory.');
            }
            // verify required parameter 'dateIntervalTo' is not null or undefined
            if (dateIntervalTo === null || dateIntervalTo === undefined) {
                throw new RequiredError('dateIntervalTo','Required parameter dateIntervalTo was null or undefined when calling getEscrowAccountBalanceHistory.');
            }
            const localVarPath = `/api/account-monitoring/{networkId}/getEscrowAccountBalanceHistory`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (dateIntervalFrom !== undefined) { 
                localVarFormParams.set('dateIntervalFrom', dateIntervalFrom as any);
            }
    
            if (dateIntervalTo !== undefined) { 
                localVarFormParams.set('dateIntervalTo', dateIntervalTo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the transaction history for the specified date interval
         * @summary Get the bridge account\'s transaction history
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountTransactionHistory: async (networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getEscrowAccountTransactionHistory.');
            }
            // verify required parameter 'dateIntervalFrom' is not null or undefined
            if (dateIntervalFrom === null || dateIntervalFrom === undefined) {
                throw new RequiredError('dateIntervalFrom','Required parameter dateIntervalFrom was null or undefined when calling getEscrowAccountTransactionHistory.');
            }
            // verify required parameter 'dateIntervalTo' is not null or undefined
            if (dateIntervalTo === null || dateIntervalTo === undefined) {
                throw new RequiredError('dateIntervalTo','Required parameter dateIntervalTo was null or undefined when calling getEscrowAccountTransactionHistory.');
            }
            const localVarPath = `/api/account-monitoring/{networkId}/getEscrowAccountTransactionHistory`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (dateIntervalFrom !== undefined) { 
                localVarFormParams.set('dateIntervalFrom', dateIntervalFrom as any);
            }
    
            if (dateIntervalTo !== undefined) { 
                localVarFormParams.set('dateIntervalTo', dateIntervalTo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountMonitoringApi - functional programming interface
 * @export
 */
export const AccountMonitoringApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Retrieves the balance of a specific address
         * @summary Get account balance for a specific address
         * @param {string} networkId 
         * @param {string} account The account address to retrieve the balance for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccountBalance(networkId: string, account: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Balance>> {
            const localVarAxiosArgs = await AccountMonitoringApiAxiosParamCreator(configuration).getAccountBalance(networkId, account, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the asset references associated with the bridge account
         * @summary Get asset references owned by the bridge
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBridgeAssetRefs(networkId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssetRef>>> {
            const localVarAxiosArgs = await AccountMonitoringApiAxiosParamCreator(configuration).getBridgeAssetRefs(networkId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the current balance of the account
         * @summary Get the bridge account\'s balance
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEscrowAccountBalance(networkId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Balance>> {
            const localVarAxiosArgs = await AccountMonitoringApiAxiosParamCreator(configuration).getEscrowAccountBalance(networkId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the balance of the account at a specific date
         * @summary Get escrow account balance at a specific date
         * @param {string} networkId 
         * @param {string} date The date to retrieve the balance for in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEscrowAccountBalanceAtDate(networkId: string, date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Balance>> {
            const localVarAxiosArgs = await AccountMonitoringApiAxiosParamCreator(configuration).getEscrowAccountBalanceAtDate(networkId, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the balance history for the specified date interval
         * @summary Get escrow account balance history
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEscrowAccountBalanceHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceList>> {
            const localVarAxiosArgs = await AccountMonitoringApiAxiosParamCreator(configuration).getEscrowAccountBalanceHistory(networkId, dateIntervalFrom, dateIntervalTo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the transaction history for the specified date interval
         * @summary Get the bridge account\'s transaction history
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEscrowAccountTransactionHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionHistory>> {
            const localVarAxiosArgs = await AccountMonitoringApiAxiosParamCreator(configuration).getEscrowAccountTransactionHistory(networkId, dateIntervalFrom, dateIntervalTo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountMonitoringApi - factory interface
 * @export
 */
export const AccountMonitoringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Retrieves the balance of a specific address
         * @summary Get account balance for a specific address
         * @param {string} networkId 
         * @param {string} account The account address to retrieve the balance for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountBalance(networkId: string, account: string, options?: any): AxiosPromise<Balance> {
            return AccountMonitoringApiFp(configuration).getAccountBalance(networkId, account, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the asset references associated with the bridge account
         * @summary Get asset references owned by the bridge
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBridgeAssetRefs(networkId: string, options?: any): AxiosPromise<Array<AssetRef>> {
            return AccountMonitoringApiFp(configuration).getBridgeAssetRefs(networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the current balance of the account
         * @summary Get the bridge account\'s balance
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountBalance(networkId: string, options?: any): AxiosPromise<Balance> {
            return AccountMonitoringApiFp(configuration).getEscrowAccountBalance(networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the balance of the account at a specific date
         * @summary Get escrow account balance at a specific date
         * @param {string} networkId 
         * @param {string} date The date to retrieve the balance for in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountBalanceAtDate(networkId: string, date: string, options?: any): AxiosPromise<Balance> {
            return AccountMonitoringApiFp(configuration).getEscrowAccountBalanceAtDate(networkId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the balance history for the specified date interval
         * @summary Get escrow account balance history
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountBalanceHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any): AxiosPromise<BalanceList> {
            return AccountMonitoringApiFp(configuration).getEscrowAccountBalanceHistory(networkId, dateIntervalFrom, dateIntervalTo, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the transaction history for the specified date interval
         * @summary Get the bridge account\'s transaction history
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEscrowAccountTransactionHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any): AxiosPromise<TransactionHistory> {
            return AccountMonitoringApiFp(configuration).getEscrowAccountTransactionHistory(networkId, dateIntervalFrom, dateIntervalTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountMonitoringApi - object-oriented interface
 * @export
 * @class AccountMonitoringApi
 * @extends {BaseAPI}
 */
export class AccountMonitoringApi extends BaseAPI {
    /**
     * Retrieves the balance of a specific address
     * @summary Get account balance for a specific address
     * @param {string} networkId 
     * @param {string} account The account address to retrieve the balance for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountMonitoringApi
     */
    public getAccountBalance(networkId: string, account: string, options?: any) {
        return AccountMonitoringApiFp(this.configuration).getAccountBalance(networkId, account, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the asset references associated with the bridge account
     * @summary Get asset references owned by the bridge
     * @param {string} networkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountMonitoringApi
     */
    public getBridgeAssetRefs(networkId: string, options?: any) {
        return AccountMonitoringApiFp(this.configuration).getBridgeAssetRefs(networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the current balance of the account
     * @summary Get the bridge account\'s balance
     * @param {string} networkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountMonitoringApi
     */
    public getEscrowAccountBalance(networkId: string, options?: any) {
        return AccountMonitoringApiFp(this.configuration).getEscrowAccountBalance(networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the balance of the account at a specific date
     * @summary Get escrow account balance at a specific date
     * @param {string} networkId 
     * @param {string} date The date to retrieve the balance for in ISO 8601 format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountMonitoringApi
     */
    public getEscrowAccountBalanceAtDate(networkId: string, date: string, options?: any) {
        return AccountMonitoringApiFp(this.configuration).getEscrowAccountBalanceAtDate(networkId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the balance history for the specified date interval
     * @summary Get escrow account balance history
     * @param {string} networkId 
     * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
     * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountMonitoringApi
     */
    public getEscrowAccountBalanceHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any) {
        return AccountMonitoringApiFp(this.configuration).getEscrowAccountBalanceHistory(networkId, dateIntervalFrom, dateIntervalTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the transaction history for the specified date interval
     * @summary Get the bridge account\'s transaction history
     * @param {string} networkId 
     * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
     * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountMonitoringApi
     */
    public getEscrowAccountTransactionHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any) {
        return AccountMonitoringApiFp(this.configuration).getEscrowAccountTransactionHistory(networkId, dateIntervalFrom, dateIntervalTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BridgingApi - axios parameter creator
 * @export
 */
export const BridgingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Bridges out token to another network
         * @summary Bridge out tokens to another network
         * @param {string} networkId 
         * @param {BridgeOutRequest} bridgeOutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bridgeOut: async (networkId: string, bridgeOutRequest: BridgeOutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling bridgeOut.');
            }
            // verify required parameter 'bridgeOutRequest' is not null or undefined
            if (bridgeOutRequest === null || bridgeOutRequest === undefined) {
                throw new RequiredError('bridgeOutRequest','Required parameter bridgeOutRequest was null or undefined when calling bridgeOut.');
            }
            const localVarPath = `/api/bridge/{networkId}/bridgeOut`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof bridgeOutRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(bridgeOutRequest !== undefined ? bridgeOutRequest : {}) : (bridgeOutRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get contract addresses for the specified network
         * @summary Get contract addresses
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts: async (networkId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getContracts.');
            }
            const localVarPath = `/api/bridge/{networkId}/contracts`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the current status of the bridge gateways
         * @summary Get status of the bridge gateways
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/bridge/getStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Issues a new asset reference
         * @summary Issue an asset reference
         * @param {string} networkId 
         * @param {IssueAssetRefRequest} issueAssetRefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueAssetRef: async (networkId: string, issueAssetRefRequest: IssueAssetRefRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling issueAssetRef.');
            }
            // verify required parameter 'issueAssetRefRequest' is not null or undefined
            if (issueAssetRefRequest === null || issueAssetRefRequest === undefined) {
                throw new RequiredError('issueAssetRefRequest','Required parameter issueAssetRefRequest was null or undefined when calling issueAssetRef.');
            }
            const localVarPath = `/api/bridge/{networkId}/issueAssetRef`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof issueAssetRefRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(issueAssetRefRequest !== undefined ? issueAssetRefRequest : {}) : (issueAssetRefRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * After a failed bridge out, the escrow must be rolled back as well
         * @summary Rollback an escrow operation
         * @param {string} networkId 
         * @param {RollbackEscrowRequest} rollbackEscrowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackEscrow: async (networkId: string, rollbackEscrowRequest: RollbackEscrowRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling rollbackEscrow.');
            }
            // verify required parameter 'rollbackEscrowRequest' is not null or undefined
            if (rollbackEscrowRequest === null || rollbackEscrowRequest === undefined) {
                throw new RequiredError('rollbackEscrowRequest','Required parameter rollbackEscrowRequest was null or undefined when calling rollbackEscrow.');
            }
            const localVarPath = `/api/bridge/{networkId}/rollbackEscrow`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof rollbackEscrowRequest !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(rollbackEscrowRequest !== undefined ? rollbackEscrowRequest : {}) : (rollbackEscrowRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BridgingApi - functional programming interface
 * @export
 */
export const BridgingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Bridges out token to another network
         * @summary Bridge out tokens to another network
         * @param {string} networkId 
         * @param {BridgeOutRequest} bridgeOutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bridgeOut(networkId: string, bridgeOutRequest: BridgeOutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VoidResponse>> {
            const localVarAxiosArgs = await BridgingApiAxiosParamCreator(configuration).bridgeOut(networkId, bridgeOutRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get contract addresses for the specified network
         * @summary Get contract addresses
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContracts(networkId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Contracts>> {
            const localVarAxiosArgs = await BridgingApiAxiosParamCreator(configuration).getContracts(networkId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the current status of the bridge gateways
         * @summary Get status of the bridge gateways
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceStatus>> {
            const localVarAxiosArgs = await BridgingApiAxiosParamCreator(configuration).getStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Issues a new asset reference
         * @summary Issue an asset reference
         * @param {string} networkId 
         * @param {IssueAssetRefRequest} issueAssetRefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issueAssetRef(networkId: string, issueAssetRefRequest: IssueAssetRefRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IssueAssetRefResponse>> {
            const localVarAxiosArgs = await BridgingApiAxiosParamCreator(configuration).issueAssetRef(networkId, issueAssetRefRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * After a failed bridge out, the escrow must be rolled back as well
         * @summary Rollback an escrow operation
         * @param {string} networkId 
         * @param {RollbackEscrowRequest} rollbackEscrowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rollbackEscrow(networkId: string, rollbackEscrowRequest: RollbackEscrowRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RollbackEscrowResponse>> {
            const localVarAxiosArgs = await BridgingApiAxiosParamCreator(configuration).rollbackEscrow(networkId, rollbackEscrowRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BridgingApi - factory interface
 * @export
 */
export const BridgingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Bridges out token to another network
         * @summary Bridge out tokens to another network
         * @param {string} networkId 
         * @param {BridgeOutRequest} bridgeOutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bridgeOut(networkId: string, bridgeOutRequest: BridgeOutRequest, options?: any): AxiosPromise<VoidResponse> {
            return BridgingApiFp(configuration).bridgeOut(networkId, bridgeOutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get contract addresses for the specified network
         * @summary Get contract addresses
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContracts(networkId: string, options?: any): AxiosPromise<Contracts> {
            return BridgingApiFp(configuration).getContracts(networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the current status of the bridge gateways
         * @summary Get status of the bridge gateways
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus(options?: any): AxiosPromise<ServiceStatus> {
            return BridgingApiFp(configuration).getStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * Issues a new asset reference
         * @summary Issue an asset reference
         * @param {string} networkId 
         * @param {IssueAssetRefRequest} issueAssetRefRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issueAssetRef(networkId: string, issueAssetRefRequest: IssueAssetRefRequest, options?: any): AxiosPromise<IssueAssetRefResponse> {
            return BridgingApiFp(configuration).issueAssetRef(networkId, issueAssetRefRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * After a failed bridge out, the escrow must be rolled back as well
         * @summary Rollback an escrow operation
         * @param {string} networkId 
         * @param {RollbackEscrowRequest} rollbackEscrowRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rollbackEscrow(networkId: string, rollbackEscrowRequest: RollbackEscrowRequest, options?: any): AxiosPromise<RollbackEscrowResponse> {
            return BridgingApiFp(configuration).rollbackEscrow(networkId, rollbackEscrowRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BridgingApi - object-oriented interface
 * @export
 * @class BridgingApi
 * @extends {BaseAPI}
 */
export class BridgingApi extends BaseAPI {
    /**
     * Bridges out token to another network
     * @summary Bridge out tokens to another network
     * @param {string} networkId 
     * @param {BridgeOutRequest} bridgeOutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgingApi
     */
    public bridgeOut(networkId: string, bridgeOutRequest: BridgeOutRequest, options?: any) {
        return BridgingApiFp(this.configuration).bridgeOut(networkId, bridgeOutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get contract addresses for the specified network
     * @summary Get contract addresses
     * @param {string} networkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgingApi
     */
    public getContracts(networkId: string, options?: any) {
        return BridgingApiFp(this.configuration).getContracts(networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the current status of the bridge gateways
     * @summary Get status of the bridge gateways
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgingApi
     */
    public getStatus(options?: any) {
        return BridgingApiFp(this.configuration).getStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Issues a new asset reference
     * @summary Issue an asset reference
     * @param {string} networkId 
     * @param {IssueAssetRefRequest} issueAssetRefRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgingApi
     */
    public issueAssetRef(networkId: string, issueAssetRefRequest: IssueAssetRefRequest, options?: any) {
        return BridgingApiFp(this.configuration).issueAssetRef(networkId, issueAssetRefRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * After a failed bridge out, the escrow must be rolled back as well
     * @summary Rollback an escrow operation
     * @param {string} networkId 
     * @param {RollbackEscrowRequest} rollbackEscrowRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BridgingApi
     */
    public rollbackEscrow(networkId: string, rollbackEscrowRequest: RollbackEscrowRequest, options?: any) {
        return BridgingApiFp(this.configuration).rollbackEscrow(networkId, rollbackEscrowRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InfoApi - axios parameter creator
 * @export
 */
export const InfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractsInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/info/contracts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoApi - functional programming interface
 * @export
 */
export const InfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContractsInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contract>>> {
            const localVarAxiosArgs = await InfoApiAxiosParamCreator(configuration).getContractsInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * InfoApi - factory interface
 * @export
 */
export const InfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContractsInfo(options?: any): AxiosPromise<Array<Contract>> {
            return InfoApiFp(configuration).getContractsInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InfoApi - object-oriented interface
 * @export
 * @class InfoApi
 * @extends {BaseAPI}
 */
export class InfoApi extends BaseAPI {
    /**
     * 
     * @summary Returns the name and addresses to all Hyperledger Besu contracts that the app connects to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoApi
     */
    public getContractsInfo(options?: any) {
        return InfoApiFp(this.configuration).getContractsInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NetworkInfoApi - axios parameter creator
 * @export
 */
export const NetworkInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get bridge node status
         * @summary Get bridge node status
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBridgeNodeStatus: async (networkId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getBridgeNodeStatus.');
            }
            const localVarPath = `/api/network-info/{networkId}/getBridgeNodeStatus`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Network gateway status
         * @summary Get status of the network gateways
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkGatewayStatus: async (networkId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getNetworkGatewayStatus.');
            }
            const localVarPath = `/api/network-info/{networkId}/getStatus`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NetworkInfoApi - functional programming interface
 * @export
 */
export const NetworkInfoApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get bridge node status
         * @summary Get bridge node status
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBridgeNodeStatus(networkId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceStatus>> {
            const localVarAxiosArgs = await NetworkInfoApiAxiosParamCreator(configuration).getBridgeNodeStatus(networkId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get Network gateway status
         * @summary Get status of the network gateways
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNetworkGatewayStatus(networkId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceStatus>> {
            const localVarAxiosArgs = await NetworkInfoApiAxiosParamCreator(configuration).getNetworkGatewayStatus(networkId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NetworkInfoApi - factory interface
 * @export
 */
export const NetworkInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get bridge node status
         * @summary Get bridge node status
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBridgeNodeStatus(networkId: string, options?: any): AxiosPromise<ServiceStatus> {
            return NetworkInfoApiFp(configuration).getBridgeNodeStatus(networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Network gateway status
         * @summary Get status of the network gateways
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNetworkGatewayStatus(networkId: string, options?: any): AxiosPromise<ServiceStatus> {
            return NetworkInfoApiFp(configuration).getNetworkGatewayStatus(networkId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NetworkInfoApi - object-oriented interface
 * @export
 * @class NetworkInfoApi
 * @extends {BaseAPI}
 */
export class NetworkInfoApi extends BaseAPI {
    /**
     * Get bridge node status
     * @summary Get bridge node status
     * @param {string} networkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkInfoApi
     */
    public getBridgeNodeStatus(networkId: string, options?: any) {
        return NetworkInfoApiFp(this.configuration).getBridgeNodeStatus(networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Network gateway status
     * @summary Get status of the network gateways
     * @param {string} networkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NetworkInfoApi
     */
    public getNetworkGatewayStatus(networkId: string, options?: any) {
        return NetworkInfoApiFp(this.configuration).getNetworkGatewayStatus(networkId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OversightApi - axios parameter creator
 * @export
 */
export const OversightApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
         * @summary The total amount of currency in the network
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupply: async (networkId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getTotalSupply.');
            }
            const localVarPath = `/api/oversight/{networkId}/totalSupply`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the total amount of currency in the network at a specific date
         * @summary The total amount of currency in the network at a specific date
         * @param {string} networkId 
         * @param {string} date The date to retrieve the balance for in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupplyAtDate: async (networkId: string, date: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getTotalSupplyAtDate.');
            }
            // verify required parameter 'date' is not null or undefined
            if (date === null || date === undefined) {
                throw new RequiredError('date','Required parameter date was null or undefined when calling getTotalSupplyAtDate.');
            }
            const localVarPath = `/api/oversight/{networkId}/totalSupplyAtDate`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (date !== undefined) { 
                localVarFormParams.set('date', date as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
         * @summary The history of total amount of currency in the network
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupplyHistory: async (networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'networkId' is not null or undefined
            if (networkId === null || networkId === undefined) {
                throw new RequiredError('networkId','Required parameter networkId was null or undefined when calling getTotalSupplyHistory.');
            }
            // verify required parameter 'dateIntervalFrom' is not null or undefined
            if (dateIntervalFrom === null || dateIntervalFrom === undefined) {
                throw new RequiredError('dateIntervalFrom','Required parameter dateIntervalFrom was null or undefined when calling getTotalSupplyHistory.');
            }
            // verify required parameter 'dateIntervalTo' is not null or undefined
            if (dateIntervalTo === null || dateIntervalTo === undefined) {
                throw new RequiredError('dateIntervalTo','Required parameter dateIntervalTo was null or undefined when calling getTotalSupplyHistory.');
            }
            const localVarPath = `/api/oversight/{networkId}/totalSupplyHistory`
                .replace(`{${"networkId"}}`, encodeURIComponent(String(networkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


            if (dateIntervalFrom !== undefined) { 
                localVarFormParams.set('dateIntervalFrom', dateIntervalFrom as any);
            }
    
            if (dateIntervalTo !== undefined) { 
                localVarFormParams.set('dateIntervalTo', dateIntervalTo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OversightApi - functional programming interface
 * @export
 */
export const OversightApiFp = function(configuration?: Configuration) {
    return {
        /**
         * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
         * @summary The total amount of currency in the network
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSupply(networkId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await OversightApiAxiosParamCreator(configuration).getTotalSupply(networkId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Retrieves the total amount of currency in the network at a specific date
         * @summary The total amount of currency in the network at a specific date
         * @param {string} networkId 
         * @param {string} date The date to retrieve the balance for in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSupplyAtDate(networkId: string, date: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalSupplyHistoryRecord>> {
            const localVarAxiosArgs = await OversightApiAxiosParamCreator(configuration).getTotalSupplyAtDate(networkId, date, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
         * @summary The history of total amount of currency in the network
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalSupplyHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalSupplyHistory>> {
            const localVarAxiosArgs = await OversightApiAxiosParamCreator(configuration).getTotalSupplyHistory(networkId, dateIntervalFrom, dateIntervalTo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OversightApi - factory interface
 * @export
 */
export const OversightApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
         * @summary The total amount of currency in the network
         * @param {string} networkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupply(networkId: string, options?: any): AxiosPromise<number> {
            return OversightApiFp(configuration).getTotalSupply(networkId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the total amount of currency in the network at a specific date
         * @summary The total amount of currency in the network at a specific date
         * @param {string} networkId 
         * @param {string} date The date to retrieve the balance for in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupplyAtDate(networkId: string, date: string, options?: any): AxiosPromise<TotalSupplyHistoryRecord> {
            return OversightApiFp(configuration).getTotalSupplyAtDate(networkId, date, options).then((request) => request(axios, basePath));
        },
        /**
         * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
         * @summary The history of total amount of currency in the network
         * @param {string} networkId 
         * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
         * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalSupplyHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any): AxiosPromise<TotalSupplyHistory> {
            return OversightApiFp(configuration).getTotalSupplyHistory(networkId, dateIntervalFrom, dateIntervalTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OversightApi - object-oriented interface
 * @export
 * @class OversightApi
 * @extends {BaseAPI}
 */
export class OversightApi extends BaseAPI {
    /**
     * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
     * @summary The total amount of currency in the network
     * @param {string} networkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OversightApi
     */
    public getTotalSupply(networkId: string, options?: any) {
        return OversightApiFp(this.configuration).getTotalSupply(networkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the total amount of currency in the network at a specific date
     * @summary The total amount of currency in the network at a specific date
     * @param {string} networkId 
     * @param {string} date The date to retrieve the balance for in ISO 8601 format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OversightApi
     */
    public getTotalSupplyAtDate(networkId: string, date: string, options?: any) {
        return OversightApiFp(this.configuration).getTotalSupplyAtDate(networkId, date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The total amount of currency (wNOK or wNOK*) in the network specified by networkId
     * @summary The history of total amount of currency in the network
     * @param {string} networkId 
     * @param {string} dateIntervalFrom Start date of the transaction history interval in ISO 8601 format
     * @param {string} dateIntervalTo End date of the transaction history interval in ISO 8601 format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OversightApi
     */
    public getTotalSupplyHistory(networkId: string, dateIntervalFrom: string, dateIntervalTo: string, options?: any) {
        return OversightApiFp(this.configuration).getTotalSupplyHistory(networkId, dateIntervalFrom, dateIntervalTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StaticDataApi - axios parameter creator
 * @export
 */
export const StaticDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a mapping of account numbers of the legal entities across networks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityNameMapping: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/static-data/getEntityNameMapping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StaticDataApi - functional programming interface
 * @export
 */
export const StaticDataApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns a mapping of account numbers of the legal entities across networks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEntityNameMapping(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityNameMapping>>> {
            const localVarAxiosArgs = await StaticDataApiAxiosParamCreator(configuration).getEntityNameMapping(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StaticDataApi - factory interface
 * @export
 */
export const StaticDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Returns a mapping of account numbers of the legal entities across networks
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEntityNameMapping(options?: any): AxiosPromise<Array<EntityNameMapping>> {
            return StaticDataApiFp(configuration).getEntityNameMapping(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StaticDataApi - object-oriented interface
 * @export
 * @class StaticDataApi
 * @extends {BaseAPI}
 */
export class StaticDataApi extends BaseAPI {
    /**
     * 
     * @summary Returns a mapping of account numbers of the legal entities across networks
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StaticDataApi
     */
    public getEntityNameMapping(options?: any) {
        return StaticDataApiFp(this.configuration).getEntityNameMapping(options).then((request) => request(this.axios, this.basePath));
    }
}


