import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AssetRef, EntityNameMapping } from "../generated-client";
import {
  AssetRefWithUserFriendlyNames,
  transformAssetRefs,
} from "../utils/helpers";
import { useCallback, useState } from "react";
import { accountMonitoringApi } from "../apiClient";
import { LoadingButton } from "@mui/lab";
import RefreshIcon from "@mui/icons-material/Update";

export default function BasicTable(
  networkId: string,
  nameMapping: EntityNameMapping[],
) {
  const [loadingAssetRefs, setLoadingAssetRefs] = useState(false);
  const [assetRefs, setAssetRefs] = useState<AssetRef[]>([]);
  const [listHasBeenInitialized, setListHasBeenInitialized] =
    useState<boolean>(false);

  const fetchAssetRefs = useCallback(async () => {
    setLoadingAssetRefs(true);
    try {
      const response = await accountMonitoringApi.getBridgeAssetRefs(networkId);
      setAssetRefs(response.data);
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.log(err);
      } else {
        console.log("An unexpected error type occurred");
      }
    }
    setLoadingAssetRefs(false);
  }, [networkId]);

  if (!listHasBeenInitialized) {
    fetchAssetRefs();
    setListHasBeenInitialized(true);
  }

  const handleGetAssetRefsClick = () => {
    fetchAssetRefs();
  };

  const transformedAssetRefs = transformAssetRefs(
    assetRefs,
    nameMapping,
    networkId,
  );

  if (!transformedAssetRefs) return <>Loading...</>;

  return (
    <div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 200 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Asset Ref ID</TableCell>
              <TableCell>Owner</TableCell>
              <TableCell>Remote Recipient</TableCell>
              <TableCell>Remote Network</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell align="right">
                <LoadingButton
                  loading={loadingAssetRefs}
                  variant="outlined"
                  onClick={handleGetAssetRefsClick}
                  size="small"
                  sx={{ mx: 1, align: "right" }}
                >
                  <RefreshIcon sx={{ m: 0 }} />
                </LoadingButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transformedAssetRefs.length === 0 ? (
              <TableRow> No asset references found. </TableRow>
            ) : (
              transformedAssetRefs!.map((assetRef) => (
                <TableRow
                  key={assetRef.assetRefId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {assetRef.assetRefId}
                  </TableCell>
                  <TableCell>
                    {assetRef.ownerUserFriendlyName
                      ? assetRef.ownerUserFriendlyName
                      : assetRef.owner}
                  </TableCell>
                  <TableCell>
                    {assetRef.remoteRecipientUserFriendlyName
                      ? assetRef.remoteRecipientUserFriendlyName
                      : assetRef.remoteRecipient}
                  </TableCell>
                  <TableCell>{assetRef.remoteNetwork}</TableCell>
                  <TableCell>{assetRef.refwNOKAmount}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
