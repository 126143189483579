import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { networkInfoApi } from "../apiClient";
import { EntityNameMapping } from "../generated-client";
import { transformAccountNames } from "../utils/helpers";
import React, { useEffect, useState } from "react";
import { getPollingInterval } from "./Environment";

export const AllParticipants = (
  networkId: string,
  nameMapping: EntityNameMapping[],
) => {
  const [participants, setParticipants] = useState<string[]>([]);

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const response =
          await networkInfoApi.getAllNetworkParticipants(networkId);
        setParticipants(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchParticipants();

    const intervalId = setInterval(fetchParticipants, getPollingInterval()); // Fetch every polling interval

    return () => clearInterval(intervalId);
  }, [networkId]);

  const transformedParticipants = transformAccountNames(
    participants,
    nameMapping,
    networkId,
  );

  if (!transformedParticipants || transformedParticipants.length === 0)
    return <>Loading...</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Account Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transformedParticipants.map((accountNo: string, index: number) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {accountNo}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
