import { green, pink, red } from "@mui/material/colors";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useEffect, useState } from "react";
import { bridgingApi, networkInfoApi } from "../apiClient";
import { ServiceStatus } from "../generated-client";
import { getPollingInterval } from "./Environment";
import { NetworkType } from "./NetworkType";
import { Box } from "@mui/material";

function statusIcon(alive: boolean | undefined | null) {
  switch (alive) {
    case true:
      return <CheckCircleOutlineOutlinedIcon sx={{ color: green[500] }} />;
    case false:
      return <ErrorOutlineOutlinedIcon sx={{ color: red[500] }} />;
    default:
      return <HelpOutlineOutlinedIcon sx={{ color: pink[500] }} />;
  }
}

export default function StatusIndicator(
  networkId: string,
  networkType: NetworkType,
) {
  const [networkGatewayStatus, setNetworkGatewayStatus] = useState<
    ServiceStatus | undefined
  >(undefined);
  const [bridgeNodeStatus, setBridgeNodeStatus] = useState<
    ServiceStatus | undefined
  >(undefined);
  const [bridgeStatus, setBridgeStatus] = useState<ServiceStatus | undefined>(
    undefined,
  );

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await bridgingApi.getStatus();
        setBridgeStatus(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchStatus();
    const intervalId = setInterval(fetchStatus, getPollingInterval()); // Fetch every polling interval
    return () => clearInterval(intervalId);
  }, [networkId]);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await networkInfoApi.getBridgeNodeStatus(networkId);
        setBridgeNodeStatus(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchStatus();
    const intervalId = setInterval(fetchStatus, getPollingInterval()); // Fetch every polling interval
    return () => clearInterval(intervalId);
  }, [networkId]);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response =
          await networkInfoApi.getNetworkGatewayStatus(networkId);
        setNetworkGatewayStatus(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchStatus();
    const intervalId = setInterval(fetchStatus, getPollingInterval()); // Fetch every polling interval
    return () => clearInterval(intervalId);
  }, [networkId]);

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          maxWidth: "100%",
          bgcolor: "background.paper",
          boxShadow: 1,
          borderRadius: 1,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          maxWidth="60%"
        >
          <ListItemText
            primary="Bridge"
            secondary={"Alive: " + (bridgeStatus?.isAlive ?? null)}
          />
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "background.paper" }}>
              {statusIcon(bridgeStatus?.isAlive)}
            </Avatar>
          </ListItemAvatar>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          maxWidth="90%"
        >
          <ListItemText
            primary="Gateway"
            secondary={"Alive: " + (networkGatewayStatus?.isAlive ?? null)}
          />
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "background.paper" }}>
              {statusIcon(networkGatewayStatus?.isAlive)}
            </Avatar>
          </ListItemAvatar>
        </Box>
        {networkType == NetworkType.FABRIC && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            maxWidth="90%"
          >
            <ListItemText
              primary="Node"
              secondary={"Alive: " + (bridgeNodeStatus?.isAlive ?? null)}
            />
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "background.paper" }}>
                {statusIcon(bridgeNodeStatus?.isAlive)}
              </Avatar>
            </ListItemAvatar>
          </Box>
        )}
      </Box>
    </div>
  );
}
