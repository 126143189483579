import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { networkProperties } from "../utils/networkProperties";

interface SelectExternalNetworkDropdownMenuProps {
  onSelect: (networkId: string) => void;
  placeholder: string;
}

type OptionType = {
  networkId: string;
  label: string;
  value: string;
};

const DropdownMenu: React.FC<SelectExternalNetworkDropdownMenuProps> = ({
  onSelect,
  placeholder,
}) => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OptionType>>(null);

  const options: OptionType[] = networkProperties.map((network) => ({
    networkId: network.networkId,
    label: network.label,
    value: network.networkId,
  }));

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option);
    onSelect(option?.networkId ?? "");
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      styles={{
        container: (provided, state) => ({
          ...provided,
          width: 280,
          fontFamily: "Open Sans, sans-serif",
        }),
      }}
      placeholder={placeholder}
    />
  );
};

export default DropdownMenu;
