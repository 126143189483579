import StatusIndicator from "../components/StatusIndicator";
import ContractsTable from "../components/ContractsTable";
import { NetworkType } from "../components/NetworkType";
import { networkProperties } from "../utils/networkProperties";

const InfoPage = () => {
  return (
    <div className="content-area">
      <div>
        <h2>Fabric Status</h2>
        {StatusIndicator("fabric", NetworkType.FABRIC)}
      </div>
      {networkProperties.map((network) => (
        <div key={network.networkId}>
          <h2>{`${network.label} Status`}</h2>
          {StatusIndicator(network.networkId, NetworkType.BESU)}
        </div>
      ))}
      <div>
        <h2>Contracts in use</h2>
        {ContractsTable()}
      </div>
    </div>
  );
};

export default InfoPage;
