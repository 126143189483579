import { LineChart } from "@mui/x-charts/LineChart";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import HistoryRangePicker from "./HistoryRangePicker";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { accountMonitoringApi, oversightApi } from "../apiClient";
import { Balance, BalanceList } from "../generated-client";
import UpdateIcon from "@mui/icons-material/Update";
import dayjs, { Dayjs } from "dayjs";
import { networkProperties } from "../utils/networkProperties";
import { getSmoothedTimeSeries } from "../utils/helpers";

interface SeriesData {
  data: number[];
  color: string;
  timestamps: Date[];
  label: string;
}

export default function TotalSupplyHistoryView() {
  const wholesaleNetworkId = "fabric";
  const externalNetworkIdList = networkProperties.map(
    ({ networkId }) => networkId,
  );

  const [tFrom, setTFrom] = useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [tTo, setTTo] = useState<Dayjs | null>(dayjs.utc());

  const [tFromSelected, setTFromSelected] = useState<Dayjs | null>(null);
  const [tToSelected, setTToSelected] = useState<Dayjs | null>(null);

  const [externalSupplyHistory, setExternalSupplyHistory] = useState<{
    [key: string]: BalanceList;
  }>({});
  const [wholesaleBalanceHistory, setWholesaleBalanceHistory] =
    useState<BalanceList>({
      list: [],
    });
  const [loadingExternalSupplyHistory, setLoadingExternalSupplyHistory] =
    useState(false);
  const [loadingWholesaleBalanceHistory, setLoadingWholesaleBalanceHistory] =
    useState(false);

  const [externalSupplyAtTTo, setExternalSupplyAtTTo] = useState<{
    [key: string]: Balance;
  }>({});
  const [loadingExternalSupplyAtTTo, setLoadingExternalSupplyAtTTo] =
    useState(false);
  const [wholesaleBalanceAtTTo, setWholesaleBalanceAtTTo] = useState<Balance>({
    accountNo: "",
    amount: 0,
    timestamp: "",
  });
  const [loadingWholesaleBalanceAtTTo, setLoadingWholesaleBalanceAtTTo] =
    useState(false);

  const wholesaleSeries: SeriesData = {
    ...processBalances(
      wholesaleBalanceHistory.list,
      tFromSelected,
      tToSelected,
      wholesaleBalanceAtTTo,
      "#f07d00",
    ),
    label: "Wholesale under Escrow",
  };

  const externalSeries: SeriesData[] = Object.keys(externalSupplyHistory)
    .map((networkId) => {
      const network = networkProperties.find((n) => n.networkId === networkId);
      if (network) {
        return {
          ...processBalances(
            externalSupplyHistory[networkId].list,
            tFromSelected,
            tToSelected,
            externalSupplyAtTTo[networkId],
            network.color,
          ),
          label: `Total ${network.label || ""} Supply`,
        };
      } else {
        console.warn(`Network ID ${networkId} not found in networkProperties`);
        return null;
      }
    })
    .filter(Boolean) as SeriesData[];

  const seriesData: SeriesData[] = [wholesaleSeries, ...externalSeries];

  useEffect(() => {
    const fetch = async () => {
      if (
        tFromSelected &&
        tToSelected &&
        !loadingWholesaleBalanceHistory &&
        !loadingExternalSupplyHistory &&
        !loadingWholesaleBalanceAtTTo &&
        !loadingExternalSupplyAtTTo
      ) {
        setLoadingWholesaleBalanceHistory(true);
        setLoadingExternalSupplyHistory(true);
        setLoadingWholesaleBalanceAtTTo(true);
        setLoadingExternalSupplyAtTTo(true);
        const tFromString = tFromSelected.toISOString();
        const tToString = tToSelected.toISOString();
        try {
          // get balance / supply history
          const wholesaleHistoryResponse =
            await accountMonitoringApi.getEscrowAccountBalanceHistory(
              wholesaleNetworkId,
              tFromString,
              tToString,
            );
          let wholesaleHistoryList: BalanceList = { list: [] };
          if (wholesaleHistoryResponse.data.list) {
            wholesaleHistoryList.list = wholesaleHistoryResponse.data.list.sort(
              (a, b) => b.timestamp!.localeCompare(a.timestamp!),
            );
            setWholesaleBalanceHistory(wholesaleHistoryList);
          }

          const externalHistoryResponses: Record<string, BalanceList> = {};
          for (const networkId of externalNetworkIdList) {
            const externalHistoryResponse =
              await oversightApi.getTotalSupplyHistory(
                networkId,
                tFromString,
                tToString,
              );
            let externalHistoryList: BalanceList = { list: [] };
            if (externalHistoryResponse.data.list) {
              externalHistoryList.list = externalHistoryResponse.data.list.sort(
                (a, b) => b.timestamp!.localeCompare(a.timestamp!),
              );
              externalHistoryResponses[networkId] = externalHistoryList;
            }
          }
          setExternalSupplyHistory(externalHistoryResponses);

          // get balance / supply at tTo, in case history is empty
          const wholesaleAtTToResponse =
            await accountMonitoringApi.getEscrowAccountBalanceAtDate(
              wholesaleNetworkId,
              tToString,
            );
          setWholesaleBalanceAtTTo(wholesaleAtTToResponse.data);

          const externalAtTToResponses: Record<string, Balance> = {};
          for (const networkId of externalNetworkIdList) {
            const externalAtTToResponse =
              await oversightApi.getTotalSupplyAtDate(networkId, tToString);
            externalAtTToResponses[networkId] = externalAtTToResponse.data;
          }
          setExternalSupplyAtTTo(externalAtTToResponses);
        } catch (error: unknown) {
          if (error instanceof Error) {
            console.error(error.message);
          } else {
            console.error("An unexpected error type occurred");
          }
        }
        setLoadingWholesaleBalanceHistory(false);
        setLoadingExternalSupplyHistory(false);
        setLoadingWholesaleBalanceAtTTo(false);
        setLoadingExternalSupplyAtTTo(false);
      }
    };
    fetch();
  }, [tFromSelected, tToSelected]);

  function handleTimeRangeUpdateClick() {
    setTFromSelected(tFrom);
    setTToSelected(tTo);
  }

  if (tFrom && tTo) {
    if (!tFromSelected && !tToSelected) {
      handleTimeRangeUpdateClick();
    }
  }

  function processBalances(
    balances: Balance[] | undefined,
    tFrom: Dayjs | null,
    tTo: Dayjs | null,
    tToBalance: any | undefined,
    color: string,
  ) {

    if (!balances || !tFrom || !tTo || !tToBalance) {
      return {
        data: [],
        color,
        timestamps: [],
        label: "",
      };
    }

    const smoothedBalances = getSmoothedTimeSeries(
      balances,
      tFrom,
      tTo,
      tToBalance.amount,
    );

    return {
      data: smoothedBalances.amounts,
      color,
      timestamps: smoothedBalances.times,
      label: "",
    };
  }

  var timestamps = wholesaleSeries.timestamps;
  var minBalance = 0;
  var maxBalance = 0;
  var minBalanceSeries = 0;
  var maxBalanceSeries = 0;
  for (var series of seriesData) {
    if (series.data.length > 0) {
      minBalanceSeries = Math.min(...series.data);
      maxBalanceSeries = Math.max(...series.data);
    }
    minBalance = Math.min(minBalance, minBalanceSeries);
    maxBalance = Math.max(maxBalance, maxBalanceSeries);
  }
  if (minBalance === maxBalance) {
    maxBalance = maxBalance + 10;
  }
  var diffBalance = maxBalance - minBalance;
  minBalance = Math.max(0, minBalance - 0.05 * diffBalance);
  maxBalance = maxBalance + 0.05 * diffBalance;

  return (
    <>
      {!tFromSelected || !tToSelected ? (
        <>Loading...</>
      ) : (
        <Box>
          <LineChart
            xAxis={[
              {
                data: timestamps,
                scaleType: "time",
                min: tFromSelected.toDate()!,
                max: tToSelected.toDate()!,
              },
            ]}
            yAxis={[
              {
                min: minBalance,
                max: maxBalance,
              },
            ]}
            series={seriesData.map((series) => ({
              ...series,
              curve: "stepAfter",
            }))}
            height={300}
            margin={{ left: 80 }}
          />
        </Box>
      )}
      <Box boxShadow={1} bgcolor="background.paper" borderRadius={1} p={2}>
        <Stack spacing={2} direction="row" width="95%" alignItems="center">
          {HistoryRangePicker(tFrom, setTFrom, tTo, setTTo)}
          <LoadingButton
            loading={
              loadingExternalSupplyHistory || loadingWholesaleBalanceHistory
            }
            variant="outlined"
            onClick={() => {
              handleTimeRangeUpdateClick();
            }}
          >
            <UpdateIcon />
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
}
