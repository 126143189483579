import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { accountMonitoringApi } from "../apiClient";
import { Balance, EntityNameMapping } from "../generated-client";
import { transformAccountBalance } from "../utils/helpers";
import { getPollingInterval } from "./Environment";

export default function BasicTable(
  networkId: string,
  nameMapping: EntityNameMapping[],
) {
  const [ownBalance, setOwnBalance] = useState<Balance>({
    accountNo: "",
    amount: 0,
  });

  useEffect(() => {
    const fetchOwnBalance = async () => {
      try {
        const response =
          await accountMonitoringApi.getEscrowAccountBalance(networkId);
        setOwnBalance(response.data);
      } catch (err: unknown) {
        if (err instanceof Error) {
          console.log(err);
        } else {
          console.log("An unexpected error type occurred");
        }
      }
    };
    fetchOwnBalance();

    const intervalId = setInterval(fetchOwnBalance, getPollingInterval()); // Fetch every polling interval

    return () => clearInterval(intervalId);
  }, [networkId]);

  const transformedOwnBalance = transformAccountBalance(
    ownBalance,
    nameMapping,
    networkId,
  );

  if (!transformedOwnBalance || !transformedOwnBalance.accountNo)
    return <>Loading...</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Account Number</TableCell>
            <TableCell align="right">Balance wNOK</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>{transformedOwnBalance.accountNo}</TableCell>
            <TableCell align="right">{transformedOwnBalance.amount}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
