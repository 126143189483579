import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";

interface TotalBalancesTableProps {
  escrowAmount?: number;
  totalSupplies: Record<string, number | null>;
}

export default function TotalBalancesTable({
  escrowAmount,
  totalSupplies,
}: TotalBalancesTableProps) {
  const [balances, setBalances] = useState<
    { networkId: string; amount: number }[]
  >([]);

  useEffect(() => {
    const transformedTotalSupplies = Object.entries(totalSupplies).map(
      ([networkId, amount]) => {
        return {
          networkId,
          amount: amount ?? 0,
        };
      },
    );
    setBalances(transformedTotalSupplies);
  }, [escrowAmount, totalSupplies]);

  const totalBalance = balances.reduce(
    (sum, balance) => sum + balance.amount,
    0,
  );

  if (!balances.length) return <>Loading...</>;

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: 3, borderRadius: 2, overflow: "hidden" }}
    >
      <Table sx={{ minWidth: 200 }} aria-label="balance table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
            <TableCell
              sx={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                paddingLeft: "16px",
              }}
            >
              Network ID
            </TableCell>
            <TableCell
              align="right"
              sx={{
                fontWeight: "bold",
                fontSize: "1.2rem",
                paddingRight: "16px",
              }}
            >
              Balance [NOK]
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {balances.map((balance, index) => (
            <TableRow
              key={index}
              sx={{
                backgroundColor: index % 2 === 0 ? "#fafafa" : "#ffffff",
                "&:hover": { backgroundColor: "#f1f1f1" },
              }}
            >
              <TableCell sx={{ paddingLeft: "16px" }}>
                {balance.networkId}
              </TableCell>
              <TableCell align="right" sx={{ paddingRight: "16px" }}>
                {balance.amount}
              </TableCell>
            </TableRow>
          ))}
          <TableRow sx={{ backgroundColor: "#e0e0e0" }}>
            <TableCell sx={{ fontWeight: "bold", paddingLeft: "16px" }}>
              Total Supply
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: "bold", paddingRight: "16px" }}
            >
              {totalBalance}
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: "#e0e0e0" }}>
            <TableCell sx={{ fontWeight: "bold", paddingLeft: "16px" }}>
              Total Escrow
            </TableCell>
            <TableCell
              align="right"
              sx={{ fontWeight: "bold", paddingRight: "16px" }}
            >
              {escrowAmount}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
