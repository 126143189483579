//TODO: implement API call to retrieve networkProperties (CBDCHYP-596)
export const networkProperties = [
  {
    networkId: "appnok",
    label: "Besu AppNok",
    color: "#00bcd4",
  },
  {
    networkId: "rnok",
    label: "Besu Retail",
    color: "#003f87",
  },
];
