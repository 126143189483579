import { LineChart } from "@mui/x-charts/LineChart";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import { Balance } from "../generated-client";
import { getSmoothedTimeSeries } from "../utils/helpers";

let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default function BalancesTimeline(
  balances: Array<Balance> | undefined,
  tFrom: Dayjs | null,
  tTo: Dayjs | null,
  tToBalance: any | undefined,
) {
  if (!balances || !tFrom || !tTo || !tToBalance) return <>Loading</>;

  // check data quality
  if (
    balances.find(
      (b) =>
        b.amount === undefined ||
        b.amount === null ||
        b.timestamp === undefined,
    )
  ) {
    console.error("got invalid balance data", balances);
    return <>Error</>;
  }

  // remove duplicates
  balances = balances.filter(
    (item, index, self) =>
      index ===
      self.findIndex(
        (t) => t.amount === item.amount && t.timestamp === item.timestamp,
      ),
  );

  const smoothedBalances = getSmoothedTimeSeries(
    balances,
    tFrom,
    tTo,
    tToBalance,
  );

  var minBalance = 0;
  var maxBalance = 0;
  if (smoothedBalances.amounts.length > 0) {
    minBalance = Math.min(...smoothedBalances.amounts);
    maxBalance = Math.max(...smoothedBalances.amounts);
  }
  if (minBalance === maxBalance) {
    maxBalance = maxBalance + 10;
  }
  var diffBalance = maxBalance - minBalance;
  minBalance = Math.max(0, minBalance - 0.05 * diffBalance);
  maxBalance = maxBalance + 0.05 * diffBalance;

  return (
    <Box>
      <LineChart
        xAxis={[
          {
            data: smoothedBalances.times,
            scaleType: "time",
            min: tFrom.toDate(),
            max: tTo.toDate(),
          },
        ]}
        yAxis={[
          {
            min: minBalance,
            max: maxBalance,
          },
        ]}
        series={[
          {
            curve: "stepAfter",
            data: smoothedBalances.amounts,
            color: "#f07d00",
          },
        ]}
        height={300}
        margin={{ left: 80 }}
      />
    </Box>
  );
}
