import BalancesTimeline from "./BalancesTimeline";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import HistoryRangePicker from "./HistoryRangePicker";
import { LoadingButton } from "@mui/lab";
import OwnTransactionsTable from "./OwnTransactionsTable";
import { useEffect, useState } from "react";
import { accountMonitoringApi } from "../apiClient";
import {
  Balance,
  BalanceList,
  EntityNameMapping,
  TransactionHistory,
} from "../generated-client";
import UpdateIcon from "@mui/icons-material/Update";
import dayjs, { Dayjs } from "dayjs";

export default function AccountHistoryView(
  networkId: string,
  nameMapping: EntityNameMapping[],
) {
  const [tFrom, setTFrom] = useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [tTo, setTTo] = useState<Dayjs | null>(dayjs.utc());

  const [tFromSelected, setTFromSelected] = useState<Dayjs | null>(null);
  const [tToSelected, setTToSelected] = useState<Dayjs | null>(null);

  const [ownBalanceHistory, setOwnBalanceHistory] = useState<BalanceList>({
    list: [],
  });
  const [loadingOwnBalanceHistory, setLoadingOwnBalanceHistory] =
    useState(false);

  const [ownBalanceAtDate, setOwnBalanceAtDate] = useState<Balance>({
    accountNo: "",
    amount: 0,
    timestamp: "",
  });
  const [loadingOwnBalanceAtDate, setLoadingOwnBalanceAtDate] = useState(false);

  const [ownTransactionHistory, setOwnTransactionHistory] =
    useState<TransactionHistory>({ transactions: [] });
  const [loadingOwnTransactionHistory, setLoadingOwnTransactionHistory] =
    useState(false);

  useEffect(() => {
    const fetchOwnBalanceHistory = async () => {
      if (tFromSelected && tToSelected) {
        setLoadingOwnBalanceHistory(true);
        const tFromString = tFromSelected.toISOString();
        const tToString = tToSelected.toISOString();
        try {
          const response =
            await accountMonitoringApi.getEscrowAccountBalanceHistory(
              networkId,
              tFromString,
              tToString,
            );
          setOwnBalanceHistory(response.data);
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log("An unexpected error type occurred");
          }
        }
        setLoadingOwnBalanceHistory(false);
      }
    };
    fetchOwnBalanceHistory();
  }, [tFromSelected, tToSelected, networkId]);

  useEffect(() => {
    const fetchOwnBalanceAtDate = async () => {
      if (tToSelected) {
        setLoadingOwnBalanceAtDate(true);
        const tToString = tToSelected.toISOString();
        try {
          const response =
            await accountMonitoringApi.getEscrowAccountBalanceAtDate(
              networkId,
              tToString,
            );
          setOwnBalanceAtDate(response.data);
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log("An unexpected error type occurred");
          }
        }
        setLoadingOwnBalanceAtDate(false);
      }
    };
    fetchOwnBalanceAtDate();
  }, [tToSelected, networkId]);

  useEffect(() => {
    const fetchOwnTransactionHistory = async () => {
      if (tFromSelected && tToSelected) {
        setLoadingOwnTransactionHistory(true);
        const tToString = tToSelected.toISOString();
        const tFromString = tFromSelected.toISOString();
        try {
          const response =
            await accountMonitoringApi.getEscrowAccountTransactionHistory(
              networkId,
              tFromString,
              tToString,
            );
          setOwnTransactionHistory(response.data);
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log("An unexpected error type occurred");
          }
        }
        setLoadingOwnTransactionHistory(false);
      }
    };
    fetchOwnTransactionHistory();
  }, [tFromSelected, tToSelected, networkId]);

  function handleTimeRangeUpdateClick() {
    setTFromSelected(tFrom);
    setTToSelected(tTo);
  }

  if (tFrom && tTo) {
    if (!tFromSelected && !tToSelected) {
      handleTimeRangeUpdateClick();
    }
  }

  return (
    <div>
      <h2>Balance</h2>
      {BalancesTimeline(
        ownBalanceHistory?.list,
        tFromSelected,
        tToSelected,
        ownBalanceAtDate,
      )}
      <Box boxShadow={1} bgcolor="background.paper" borderRadius={1} p={2}>
        <Stack spacing={2} direction="row" width="100%" alignItems="center">
          {HistoryRangePicker(tFrom, setTFrom, tTo, setTTo)}
          <LoadingButton
            loading={
              loadingOwnBalanceAtDate ||
              loadingOwnBalanceHistory ||
              loadingOwnTransactionHistory
            }
            variant="outlined"
            sx={{ mx: 2 }}
            onClick={() => {
              handleTimeRangeUpdateClick();
            }}
          >
            <UpdateIcon />
          </LoadingButton>
        </Stack>
      </Box>
      <h2>Transactions</h2>
      {OwnTransactionsTable(
        ownTransactionHistory?.transactions,
        networkId,
        nameMapping,
      )}
    </div>
  );
}
