import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Grid } from "@mui/material";
import React from "react";
import UnifiedDashboardComponent from "../components/UnifiedDashboardComponent";
import { NetworkType } from "../components/NetworkType";
import SelectExternalNetworkDropdownMenu from "../components/SelectExternalNetworkDropdownMenu";

dayjs.extend(utc);

type networkIdType = {
  networkId: string;
};

const ExternalDashboardPage = () => {
  const [networkId, setNetworkId] = React.useState<networkIdType>({
    networkId: "rnok",
  });

  return (
    <div className="content-area">
      <h1>Select Network</h1>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <SelectExternalNetworkDropdownMenu
            onSelect={(id) => setNetworkId({ networkId: id })}
            placeholder="Besu Retail"
          />
        </Grid>
      </Grid>

      {UnifiedDashboardComponent(networkId.networkId, NetworkType.BESU)}
    </div>
  );
};

export default ExternalDashboardPage;
