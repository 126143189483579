import BalancesTimeline from "./BalancesTimeline";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import HistoryRangePicker from "./HistoryRangePicker";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { accountMonitoringApi, oversightApi } from "../apiClient";
import { Balance, BalanceList } from "../generated-client";
import UpdateIcon from "@mui/icons-material/Update";
import dayjs, { Dayjs } from "dayjs";

export default function TotalSupplyHistoryView() {
  const networkIdBesu = "besu";
  const networkIdFabric = "fabric";

  const [tFrom, setTFrom] = useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [tTo, setTTo] = useState<Dayjs | null>(dayjs.utc());

  const [tFromSelected, setTFromSelected] = useState<Dayjs | null>(null);
  const [tToSelected, setTToSelected] = useState<Dayjs | null>(null);

  const [totalSupplyHistory, setTotalSupplyHistory] = useState<BalanceList>({
    list: [],
  });
  const [loadingTotalSupplyHistory, setLoadingTotalSupplyHistory] =
    useState(false);

  const [deviationHistory, setDeviationHistory] = useState<BalanceList>({
    list: [],
  });
  const [loadingDeviationHistory, setLoadingDeviationHistory] = useState(false);

  const [totalSupplyAtDate, setTotalSupplyAtDate] = useState<Balance>({
    accountNo: "",
    amount: 0,
    timestamp: "",
  });
  const [loadingTotalSupplyAtDat, setLoadingTotalSupplyAtDate] =
    useState(false);

  const [ownBalanceHistory, setOwnBalanceHistory] = useState<BalanceList>({
    list: [],
  });
  const [loadingOwnBalanceHistory, setLoadingOwnBalanceHistory] =
    useState(false);

  const [ownBalanceAtDate, setOwnBalanceAtDate] = useState<Balance>({
    accountNo: "",
    amount: 0,
    timestamp: "",
  });
  const [loadingOwnBalanceAtDate, setLoadingOwnBalanceAtDate] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      if (tFromSelected && tToSelected) {
        setLoadingOwnBalanceHistory(true);
        setLoadingTotalSupplyHistory(true);
        setLoadingDeviationHistory(true);
        const tFromString = tFromSelected.toISOString();
        const tToString = tToSelected.toISOString();
        try {
          const responseFabric =
            await accountMonitoringApi.getEscrowAccountBalanceHistory(
              networkIdFabric,
              tFromString,
              tToString,
            );
          const responseBesu = await oversightApi.getTotalSupplyHistory(
            networkIdBesu,
            tFromString,
            tToString,
          );

          // sort both lists
          let listFabric: BalanceList = {};
          let listBesu: BalanceList = {};
          if (responseFabric.data.list) {
            listFabric.list = responseFabric.data.list.sort((a, b) => {
              return a.timestamp!.localeCompare(b.timestamp!);
            });
          }
          if (responseBesu.data.list) {
            listBesu.list = responseBesu.data.list.sort((a, b) => {
              return a.timestamp!.localeCompare(b.timestamp!);
            });
          }

          // create a combined list with the deviation
          if (listFabric.list && listBesu.list) {
            let combinedList: Balance[] = [];
            let fab = listFabric.list;
            let bes = listBesu.list;

            let i = 0;
            let j = 0;
            let lastFabricAmount = 0;
            let lastBesuAmount = 0;
            while (i + j < fab.length + bes.length) {
              if (
                i === fab.length ||
                (j < bes.length &&
                  fab[i].timestamp!.localeCompare(bes[j].timestamp!) >= 0)
              ) {
                const b: Balance = {};
                b.timestamp = bes[j].timestamp;
                b.amount = lastFabricAmount - bes[j].amount!;
                combinedList.push(b);
                lastBesuAmount = bes[j].amount!;
                j++;
              } else if (
                j === bes.length ||
                fab[i].timestamp!.localeCompare(bes[j].timestamp!) < 0
              ) {
                const b: Balance = {};
                b.timestamp = fab[i].timestamp;
                b.amount = fab[i].amount! - lastBesuAmount;
                combinedList.push(b);
                lastFabricAmount = fab[i].amount!;
                i++;
              }
            }

            // need to sort the lists the other way round again
            listFabric.list = listFabric.list.reverse();
            listBesu.list = listBesu.list.reverse();
            combinedList = combinedList.reverse();

            setTotalSupplyHistory(listFabric);
            setOwnBalanceHistory(listBesu);
            setDeviationHistory({ list: combinedList });
          }
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log("An unexpected error type occurred");
          }
        }
        setLoadingOwnBalanceHistory(false);
        setLoadingTotalSupplyHistory(false);
        setLoadingDeviationHistory(false);
      }
    };
    fetch();
  }, [tFromSelected, tToSelected, networkIdFabric, networkIdBesu]);

  useEffect(() => {
    const fetch = async () => {
      if (tToSelected) {
        setLoadingTotalSupplyAtDate(true);
        const tToString = tToSelected.toISOString();
        try {
          const response = await oversightApi.getTotalSupplyAtDate(
            networkIdBesu,
            tToString,
          );
          setTotalSupplyAtDate(response.data);
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log("An unexpected error type occurred");
          }
        }
        setLoadingTotalSupplyAtDate(false);
      }
    };
    fetch();
  }, [tToSelected, networkIdBesu]);

  useEffect(() => {
    const fetch = async () => {
      if (tToSelected) {
        setLoadingOwnBalanceAtDate(true);
        const tToString = tToSelected.toISOString();
        try {
          const response =
            await accountMonitoringApi.getEscrowAccountBalanceAtDate(
              networkIdFabric,
              tToString,
            );
          setOwnBalanceAtDate(response.data);
        } catch (err: unknown) {
          if (err instanceof Error) {
            console.log(err);
          } else {
            console.log("An unexpected error type occurred");
          }
        }
        setLoadingOwnBalanceAtDate(false);
      }
    };
    fetch();
  }, [tToSelected, networkIdFabric]);

  function handleTimeRangeUpdateClick() {
    setTFromSelected(tFrom);
    setTToSelected(tTo);
  }

  if (tFrom && tTo) {
    if (!tFromSelected && !tToSelected) {
      handleTimeRangeUpdateClick();
    }
  }

  return (
    <>
      <h2>wNOK under Escrow</h2>
      {BalancesTimeline(
        totalSupplyHistory?.list,
        tFromSelected,
        tToSelected,
        totalSupplyAtDate,
      )}
      <h2>Total wNOK* Supply</h2>
      {BalancesTimeline(
        ownBalanceHistory?.list,
        tFromSelected,
        tToSelected,
        ownBalanceAtDate,
      )}
      <h2>Deviation [(wNOK under Escrow) - (Total wNOK* Supply)]</h2>
      {BalancesTimeline(
        deviationHistory?.list,
        tFromSelected,
        tToSelected,
        deviationHistory?.list!.length > 0
          ? deviationHistory?.list![0]
          : undefined,
      )}
      <Box boxShadow={1} bgcolor="background.paper" borderRadius={1} p={2}>
        <Stack spacing={2} direction="row" width="95%" alignItems="center">
          {HistoryRangePicker(tFrom, setTFrom, tTo, setTTo)}
          <LoadingButton
            loading={
              loadingTotalSupplyAtDat ||
              loadingTotalSupplyHistory ||
              loadingDeviationHistory ||
              loadingOwnBalanceHistory ||
              loadingTotalSupplyAtDat
            }
            variant="outlined"
            onClick={() => {
              handleTimeRangeUpdateClick();
            }}
          >
            <UpdateIcon />
          </LoadingButton>
        </Stack>
      </Box>
    </>
  );
}
